<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-shopping-cart-full"> </i> 功能室 | 功能室列表</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增功能室</el-button>
            <button type="submit" class="el-button el-button--success" @click="rowExport">
              <span>导出Excel</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-input v-model="grid.sea.Func_Room_Name" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="Func_Room_Name" label="功能室名称" width="200" align="center" />
              <el-table-column prop="Location" label="位置" width="200" align="center" />
              <el-table-column prop="Man_User_Names" label="负责人" />
              <el-table-column prop="Room_QrCode" label="二维码" align="center" width="80">
                <template slot-scope="{row}">
                  <el-image style="width: 50px; height: 50px;margin: 10px 0 5px"
                    :src="'data:image/png;base64,' + row.Room_QrCode"
                    :preview-src-list="['data:image/png;base64,' + row.Room_QrCode]">
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="400">

                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-edit" @click="show(scope.row)">详情</el-button>
                  <el-button type="text" icon="el-icon-download" @click="downCode(scope.row.Room_QrCode)">下载</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="func_edit" @on-saved="getList" />
    </div>
    <Detail :showDetail="showDetail" :model="info" @close="showDetail = false"></Detail>
  </div>
</template>

<style>
.el-radio {
  padding-top: 10px
}
</style>

<script>
import Edit from "./edit";
import Detail from "./detail"
import Wgrid from "@/components/wgrid";
import jsFileDownload from 'js-file-download'
export default {
  name: "index",
  components: { Wgrid, Edit, Detail },
  data() {
    return {
      showEwm: false,
      showDetail: false,
      info: '',
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          Func_Room_Name: '',
        },
        ls: [],
        total: 0,
        loading: false
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    filter() {
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/FUNC/FuncRoom/GetPageList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    show(r) {
      this.info = r
      this.showDetail = true
    },
    rowEdit(r) {
      this.$refs.func_edit.init(r);
    },
    rowDel(r) {
      this.whale.delConfirm(() => {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/FUNC/FuncRoom/Delete",
          data: { Id: r.ID },
          completed: function () {
            //self.filter();
            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
          }
        })
      });
    },
    downCode(img) {
      this.downLoadBase64('二维码.png', img)
    },
    downLoadBase64(filename, content) {
      let aLink = document.createElement('a');
      let blob = this.base64ToBlob(content); //new Blob([content]);

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = filename;
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
    }
    ,
    base64ToBlob(code) {
      let contentType = code.split(':')[1];
      let raw = window.atob(code);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    rowExport() {
      let self = this
      this.whale.remote.getExportResult({
        url: '/api/School/FUNC/FuncRoom/Export',
        data: { Func_Room_Name: self.grid.sea.Func_Room_Name },
        completed: function (m) {
          console.log('df', m)
          jsFileDownload(m, '功能室列表.xls')
        }
      })
    }
  }
}
</script>
